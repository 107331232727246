<template>
	<div class="fare-card">
		<div class="top">
			<div class="cabin-class">
				{{ cabinClass }}
				<div class="fare-name" v-if="fareName.split('/').length > 1">
					SPLIT FARE
				</div>
				<div class="fare-name" v-else>
					SINGLE FARE
				</div>
			</div>
			<div class="price" v-html="price.symbol + price.amount">
			</div>
		</div>
		<div class="full-description">
			<!-- We don't need pax type pricing? -->
			<span v-for="(pricing, index) in price.pax" :key="index" class="ages">
				<div class="age-type" :class="index">{{ index }}: {{ formatPrice(pricing.currency,pricing.each, 1) }}</div>
			</span>
			<div class="legs" v-for="(legs, type) in preparedDescriptionMap" :key="type">				
				<div class="leg" v-for="(leg, index) in legs.data" :key="index">
					<div class="leg-name" v-if="Object.keys(legs.data).length > 1">{{ index }}</div>
					<div class="leg-name" v-else>All flights</div>
					<div v-if="leg.data.length > 0">

						<div v-if="leg.data.filter(amenity => !amenity.isChargeable).length > 0">
							<ul v-if="leg.showChargeableExtras">
								<li v-for="(amenity, index) in leg.data.filter(amenity => !amenity.isChargeable)" :key="index">
									{{ amenity.description }}
								</li>
							</ul>
							<ul v-else>
								<li v-for="(amenity, index) in leg.data.filter(amenity => !amenity.isChargeable).slice(0, 5)" :key="index">
									{{ amenity.description }}
								</li>
							</ul>
						</div>
						<div v-else>
							No inclusions
						</div>

						<ul class="chargeable" v-if="leg.showChargeableExtras && leg.data.filter(amenity => amenity.isChargeable).length > 0">
							<div class="section">Chargeable extras</div>
							<li v-for="(amenity, index) in leg.data.filter(amenity => amenity.isChargeable)" :key="index">
								{{ amenity.description }}
							</li>
						</ul>

						<div class="show-more" v-if="leg.data.filter(amenity => !amenity.isChargeable).length > 5" @click="leg.showChargeableExtras = !leg.showChargeableExtras">
							<span v-if="leg.showChargeableExtras">View less</span>
							<span v-else>View <span class="amount">{{ (leg.data.filter(amenity => !amenity.isChargeable).length - 5) }}</span> more <span v-if="leg.data.filter(amenity => amenity.isChargeable).length > 0">and chargeable extras</span></span>
							<img class="arrow" src="@/assets/trip/arrow-up.png" :class="{ selected: leg.showChargeableExtras }"/>
						</div>
						<div class="show-more" v-else-if="leg.data.filter(amenity => amenity.isChargeable).length > 0" @click="leg.showChargeableExtras = !leg.showChargeableExtras">
							{{ leg.showChargeableExtras ? 'View less' : 'View chargeable extras' }}
							<img class="arrow" src="@/assets/trip/arrow-up.png" :class="{ selected: leg.showChargeableExtras }"/>
						</div>

					</div>
					<div v-else>
						No inclusions or chargeable extras
					</div>
				</div>
			</div>
		</div>

		<div class="button-holder" >
			<button class="button" @click="select()" :class="selected ? 'selected' : 'select'">
				{{ selected ? 'Selected' : 'Select' }}
			</button>
			<button class="button change" v-if="selected" @click="$emit('changeFare')">
				Change Fare
			</button>
		</div>

		<!-- Summary info - is this still relevant? -->
		<!-- <div class="fare-info">
			<div class="baggage-area">
				<div v-if="baggage.carry_on.bags !== false">
					<li class="carry-on" v-if="baggage.carry_on.bags != 0">
						<span class="baggage ">
						{{ baggage.carry_on.bags }} Carry On Bag(s) Included
						</span>
						<span class="weight" v-if="baggage.carry_on.max_weight != 0">(Max weight: {{baggage.carry_on.max_weight}}kg)</span>
					</li>
					<li class="baggage carry-on" v-else>No Carry On Bags Included</li>
				</div>

				<div v-if="baggage.checked.bags !== false">
					<li class="baggage checked-in" v-if="baggage.checked.bags != 0">
						{{ baggage.checked.bags }} Checked Bags Included
						<span class="weight" v-if="baggage.checked.max_weight != 0">(Max weight: {{baggage.checked.max_weight}}kg)</span>
					</li>						
					<li class="baggage" v-else>No Checked Bag Included</li>
				</div>
				<div class="information">
					<div v-for="amenity in includedBaggageAmenities" :key="amenity.code" class="included">
						<span class="text">{{ amenity.description.toLowerCase() }}</span> <span class="small" v-if="!amenity.isChargeable">Included</span>
					</div>
					<div v-for="amenity in chargeableBaggageAmenities" :key="amenity.code" class="chargeable">
						<span class="text">{{ amenity.description.toLowerCase() }}</span> <span class="small" v-if="amenity.isChargeable">For a fee</span>
					</div>
				</div>
			</div>

			<div class="cancellation">
				<li class="cancellation-fee" v-if="cancellationPolicy !== false && cancellationPolicy.amount != 0">{{ cancellationPolicy.amount }} Cancellation Fee</li>
				<li class="change-fee" v-if="changePolicy !== false && parseFloat(changePolicy.price.amount) === 0">Free Change Fee + Fare Difference</li>
				<li class="change-fee" v-else-if="changePolicy !== false && parseFloat(changePolicy.price.amount) > 0">£{{ changePolicy.price.amount }} Change Fee + Fare Difference</li>
			</div>
		</div> -->

		
	</div>
</template>

<script>
import helpers from '@/helpers/helpers.js';

export default {
	props: {
		fareKey: {
			default: "",
		},
		fareName: {
			default: "",
		},
		cabinClass: {
			default: "",
		},
		origin: {
			default: "",
		},
		destination: {
			default: "",
		},
		baggage: {
			default: "",
		},
		cancellationPolicy: {
			default: "",
		},
		changePolicy: {
			default: "",
		},
		amenities: {
			default: "",
		},
		descriptionMap: {
			default: {},
		},
		price: {
			default: "",
		},
		selectable: {
			default: false, // Defines whether we show the select button at all
		},
		selected: {
			default: false, // Defines whether the select button is "selected"
		}
	},
	data() {
		return {
			preparedDescriptionMap: [],
		}
	},
	computed: {
		// includedAmenities() {
		// 	return this.amenities.filter(amenity => amenity.isChargeable === false);
		// },
		// chargeableAmenities() {
		// 	return this.amenities.filter(amenity => amenity.isChargeable);
		// },
		// includedBaggageAmenities() {
		// 	return this.includedAmenities.filter(amenity => amenity.amenityType == "BAGGAGE");
		// },
		// chargeableBaggageAmenities() {
		// 	return this.chargeableAmenities.filter(amenity => amenity.amenityType == "BAGGAGE");
		// },
	},
	methods: {
		getImage: (img) => helpers.getImage(img),
		formatPrice: (currency, to, howmany) => helpers.formatPrice(currency, to, howmany),
		select() {
			this.$emit('selected');
		},
		changeFare() {
			this.$emit('changeFare');
		},
	},
	created() {
		// Prepare data with show/collapse variables
		Object.keys(this.descriptionMap).forEach((paxType) => {
			let arrayItem = {
				paxType: paxType,
				data: {},
			};
			Object.keys(this.descriptionMap[paxType]).forEach(legName => {
				arrayItem.data[legName] = {
					// legName: legName,
					data: this.descriptionMap[paxType][legName],
					showChargeableExtras: false,
				};
			});
			this.preparedDescriptionMap.push(arrayItem);
		});
	}
}
</script>

<style scoped>

	.fare-card .top {
		display: flex;
		justify-content: space-between;
		margin-bottom: 18px;
	}
	.fare-card .top .cabin-class {
		font-weight: 700;
		font-size: 16px;
		color: #0D90EF;
	}
	.fare-card .top .cabin-class .fare-name {
		font-size: 11px;
	}
	.fare-card .top .price {
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		color: #000000;
	}

	.fare-card .ages {
		/* position: absolute;
		top: -12px; */
	}
	.fare-card .ages .age-type {
		display: inline-block;
		background: #118AB2;
		border-radius: 10px;
		color: white;
		padding: 0 9px;
		line-height: 24px;
		margin-right: 5px;
		font-weight: 500;
		padding-left: 27px;
		text-transform:capitalize;
		margin-bottom: 6px;
	}
	.fare-card .ages .age-type.adult {
		background: url('../../assets/travellers/adult-icon.png') no-repeat 5px, linear-gradient(#118AB2, #118AB2);
		background-size: 18px;
	}
	.fare-card .ages .age-type.child {
		background: url('../../assets/travellers/child-icon.png') no-repeat 5px, linear-gradient(#118AB2, #118AB2);
		background-size: 18px;
	}
	.fare-card .ages .age-type.infant {
		background: url('../../assets/travellers/infant-icon.png') no-repeat 5px, linear-gradient(#118AB2, #118AB2);
		background-size: 18px; 
	}

	.fare-card .legs {
		background: #FFFFFF;
		border: 1px solid #EAEAEA;
		border-radius: 10px;
		padding: 29px 22px 0px 22px;
		position: relative;
		margin-bottom: 30px;
		margin-top: 12px;
	}
	.fare-card .leg {
		margin-bottom: 24px;
	}
	.fare-card .leg .leg-name {
		font-weight: 600;
		font-size: 16px;
		color: #000000;
	}
	.fare-card .leg ul {
		list-style: none;
		font-weight: 400;
		font-size: 13px;
		line-height: 13px;
		color: #50555C;
		padding-left: 0px;

	}
	.fare-card .leg ul li {
		padding-left: 18px;
		background-image: url('../../assets/alerts/alert-green.png');
		background-repeat: no-repeat;
		background-position: left;
		text-transform: lowercase;
		background-size: 10px 10px;
		margin-top: 6px;
		margin-bottom: 6px;
	}
	.fare-card .leg ul li:first-letter {
		text-transform: uppercase;
	}
	.fare-card .leg ul.chargeable li {
		background-image: url('../../assets/alerts/alert-add.png');
		background-size: 10px 10px;
	}
	.fare-card .leg .section {
		font-weight: 600;
		font-size: 12px;
		color: #118AB2;
	}
	.fare-card .leg .show-more {
		font-weight: 600;
		font-size: 12px;
		line-height: 15px;
		color: #000000;
		cursor: pointer;
		vertical-align: middle;
	}
	.fare-card .leg .show-more .arrow {
		margin: 0;
		width: 24px;
		transform: rotate(180deg);
		vertical-align: middle;
		margin-left: 5px;
	}
	.fare-card .leg .show-more .arrow.selected {
		transform: rotate(0deg);
	}
	.fare-card .leg .show-more .amount {
		display: inline-block;
		min-width: 15px;
		height: 15px;
		border-radius: 8px;
		background-color: #06D6A0;
		color: white;
		text-align: center;
		padding: 0px 1px;
		box-sizing: border-box;
		text-align: center;
	}

	.fare-card .button-holder .button {
		display: block;
		margin: 0 auto;
	}
	.fare-card .button-holder .button.select,
	.fare-card .button-holder .button.change {
		background-color: white;
		color: #118AB2;
		border: 1px solid #118AB2
	}
	.fare-card .button-holder .button.change {
		margin-top: 20px;
	}

</style>